import React, { useEffect, useRef } from 'react';
import { createRoot } from 'react-dom/client'
import Slider from '@mui/material/Slider';
import { MIXIPALETTE, MIXIDARKPALETTE } from "../../constants/AppConstants";
import { ThemeProvider, createTheme } from "@mui/material/styles";



function valuetext(value) {
	return `${value} km`;
}

const RadiusSlider = ({ defaultval, type = 'deposit', name = '' }) => {
	const [value, setValue] = React.useState(defaultval);

	let max = 100;
	let min = 0;
	let marks = [];

	if (type == 'radius') {
		marks = [
			{ value: 0, label: '0 km' },
			{ value: 25, label: '25 km' },
			{ value: 50, label: '50 km' },
			{ value: 100, label: '100 km' },
		];
	}
	else if (type == 'deposit') {
		max = 150;
		marks = [
			{ value: 0, label: '0 L' },

			{ value: 75, label: '75 L' },

			{ value: 150, label: '150 L' },
		];
	}


	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	let palette = MIXIPALETTE;
	let mark = { color: 'rgba(255, 255, 255, 0.75)', };
	// if (document.documentElement.getAttribute('data-bs-theme') == 'dark') {
	// 	palette = MixiDarkPalette;
	// 	mark = { color: 'rgba(255, 255, 255, 0.75)', }
	// }
	const theme = createTheme({
		palette: palette,
		components: {
			MuiSlider: {
				styleOverrides: {
					// Name of the slot
					markLabel: mark
				},
			},
		},
	});

	const handleInputChange = (event) => {
		setValue(event.target.value === '' ? 0 : Number(event.target.value));
	};

	const handleBlur = () => {
		if (value < 0) {
			setValue(0);
		} else if (value > 100) {
			setValue(100);
		}
	};


	return (
		<ThemeProvider theme={theme}>
			<div className="d-flex gap-4 justify-content-between mb-3">

				<Slider
					// aria-label="Área de búsqueda (km)"
					// className="px-4"
					value={value}
					getAriaValueText={valuetext}
					onChange={handleChange}
					color={'primary'}
					step={1}
					marks={marks}
					max={max}
					min={min}
					valueLabelDisplay="on"
				/>
				<input type='hidden' name={name} value={value}></input>
				{/* <div>
					<input
						type="number"
						max={max}
						min={min}
						step={1}
						className='form-control'
						value={value}
						size="small"
						onChange={handleInputChange}
						onBlur={handleBlur}

					/>
					
				</div> */}




			</div>
		</ThemeProvider>
	);
}

export default RadiusSlider;

if (document.getElementsByClassName("radiusSlider")) {
	const list = document.getElementsByClassName("radiusSlider");
	for (const item of list) {
		createRoot(item).render(<RadiusSlider {...item.dataset} />)
	}
}
