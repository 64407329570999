/**
 * Scroll Top Button
 */
document.addEventListener('DOMContentLoaded', function () {
    // const scrollBtn = document.getElementById("scrollBtn");
    const navSecondaryBottom = document.getElementById("nav-secondary-bottom");
    if (navSecondaryBottom) {
        window.onscroll = function () { scrollFunction() };
        function scrollFunction() {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                // scrollBtn.classList.add('d-lg-block');
                navSecondaryBottom.classList.add('show');
            } else {
                // scrollBtn.classList.remove('d-lg-block');
                navSecondaryBottom.classList.remove('show');
            }
        }
    }

    document.querySelectorAll('.scrollToTop')
        .forEach(elem => elem.addEventListener('click', function (e) {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        }));
})
