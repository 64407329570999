import loadable from '@loadable/component'
import React from "react";
import { createRoot } from 'react-dom/client'


const Results = loadable(() => import('./results'));


if (document.getElementById('carbuResults')) {
    const elem = document.getElementById("carbuResults");
    createRoot(elem).render(<Results {...elem.dataset} />)
}