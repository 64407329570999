// src/constants/AppConstant.js

export const COLORS = {
    primary: '#1074d8',
    secondary: '#CB3066',
    terciary: '#0EAD69',
    quaternary: '#7C52A3'
};

export const MIXIPALETTE = {
    primary: {
        main: "#1074d8"
    },
    danger: {
        main: "#CB3066"
    },
    success: {
        main: "#0EAD69"
    },
    warning: {
        main: "#ffc107"
    },
    info: {
        main: "#7C52A3"
    },
    dark: {
        main: "#212529"
    }
};

export const MIXIDARKPALETTE = {
    primary: {
        main: "#1074d8"
    },
    danger: {
        main: "#CB3066"
    },
    success: {
        main: "#0EAD69"
    },
    warning: {
        main: "#ffc107"
    },
    info: {
        main: "#7C52A3"
    },
    dark: {
        main: "#ffffff"
    }
};

export const FUELS = {
    precio_bioetanol: { label: 'Bioetanol', color: '#3F705C' },
    precio_gas_natural_comprimido: { label: 'GNC', color: '#195291' }, // Gas natural comprimido
    precio_gas_natural_licuado: { label: 'GNL', color: '#0076CB' }, // Gas natural licuado
    precio_gases_licuados_del_petroleo: { label: 'GLP', color: '#47a5e8' }, // Gases licuados del petroleo
    precio_gasoleo_a: { label: 'Gasoleo A', color: '#FFFFFF' },
    precio_gasoleo_b: { label: 'Gasoleo B', color: '#707070' },
    precio_gasoleo_premium: { label: 'Gasoleo Premium', color: '#ff9900' },
    precio_gasolina_95_e10: { label: 'Gasolina 95 E10', color: '#04a021' },
    precio_gasolina_95_e5: { label: 'Gasolina 95 E5', color: '#9cc004' },
    precio_gasolina_95_e5_premium: { label: 'Gasolina 95 E5 Premium', color: '#7b9603' },
    precio_gasolina_98_e10: { label: 'Gasolina 98 E10', color: '#3ad857' },
    precio_gasolina_98_e5: { label: 'Gasolina 98 E5', color: '#1dc13b' },
};

export const NOTIFICATION_TYPES = {
    price: "El precio no es correcto",
    not_found: "La gasolinera ya no existe",
    opening_hours_mismatch: "Los horarios de apertura son incorrectos",
    coordinates_mismatch: "Las coordenadas son incorrectas",
    other: "Otro",
};
